import * as React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";
import { useStaticQuery, graphql } from "gatsby";

const Head = ({ pageTitle, description, image, article }) => {
  const { pathname } = useLocation();
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          defaultTitle: title
          titleTemplate
          siteUrl
          defaultDescription: description
          defaultImage
        }
      }
    }
  `);

  const {
    defaultTitle,
    titleTemplate,
    siteUrl,
    defaultDescription,
    defaultImage,
  } = data.site.siteMetadata;

  const seo = {
    title: pageTitle || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathname}`,
  };
  return (
    <Helmet defaultTitle={defaultTitle} titleTemplate={titleTemplate}>
      <html lang="ja" />
      <title>{pageTitle}</title>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.url && <meta property="og:url" content={seo.url} />}
      {(article ? true : null) && <meta property="og:type" content="article" />}
      {seo.description && <meta name="description" content={description} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      {seo.images && <meta property="og:image" content={seo.image} />}
      <meta name="twitter:card" content="summary_large_image" />
      {/* {twitterUsername && (
        <meta name="twitter:creator" content={twitterUsername} />
      )} */}
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
      {seo.image && <meta name="twitter:image" content={seo.image} />}
    </Helmet>
  );
};

export default Head;
