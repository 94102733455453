import { breakpoints, typography } from "./variables";

export const mq = {
  down: {
    xs: `@media (max-width: ${breakpoints.xs - 1}px)`,
    sm: `@media (max-width: ${breakpoints.sm - 1}px)`,
    md: `@media (max-width: ${breakpoints.md - 1}px)`,
    lg: `@media (max-width: ${breakpoints.lg - 1}px)`,
    xl: `@media (max-width: ${breakpoints.xl - 1}px)`,
  },
  up: {
    xs: `@media (min-width: ${breakpoints.xs}px)`,
    sm: `@media (min-width: ${breakpoints.sm}px)`,
    md: `@media (min-width: ${breakpoints.md}px)`,
    lg: `@media (min-width: ${breakpoints.lg}px)`,
    xl: `@media (min-width: ${breakpoints.xl}px)`,
  },
};

export const getRem = (size) => {
  return `${size / typography.globalFontSize}rem`;
};
