import * as React from "react";
import { Link } from "gatsby";
import { css } from "@emotion/css";

const menu = css`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
`;
const menuItem = css`
  display: block;
  margin: 0 1rem;
  padding: 0;
  > a[aria-current="page"] {
    background-color: #a43;
  }
`;
const menuLink = css``;

const MainNav = () => {
  return (
    <nav id="navigation">
      <ul className={menu}>
        <li className={menuItem}>
          <Link to="/" className={menuLink} activeClassName="active">
            Home
          </Link>
        </li>
        <li className={menuItem}>
          <Link to="/about-us" className={menuLink} activeClassName="active">
            私たちについて
          </Link>
        </li>
        <li className={menuItem}>
          <Link to="/news" className={menuLink} activeClassName="active">
            News
          </Link>
        </li>
        <li className={menuItem}>
          <Link to="/news-more" className={menuLink} activeClassName="active">
            News2
          </Link>
        </li>
      </ul>
    </nav>
  );
};
export default MainNav;
