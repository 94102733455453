export const colors = {
  main: "#abc",
  sub: "#aaa",
  text: "#444",
  hedding: "#333",
  body: "transparent",
  border: "#707070",
  link: {
    default: "#33a",
    hover: "#66c",
  },
};

export const typography = {
  fontFamily: `-apple-system, blinkmacsystemfont, 'Helvetica Neue', 'Segoe UI',
  hiragino kaku gothic pron, 'ヒラギノ角ゴ ProN W3', arial, 'メイリオ', meiryo,
  sans-serif`,
  globalFontSize: 16, //px
  globalFontSizeSP: 14, //px
  lineHeight: 1.75,
  baseLineUnit: 12, //px
};

const maxContentW = 980;
export const structures = {
  gapUnit: `${typography.baseLineUnit * 2}px`,
  gapH: `${typography.baseLineUnit * 2}px`,
  gapV: `${typography.baseLineUnit * 4}px`,
  responsiveContentWidth: `min(calc(100vw - ${
    typography.baseLineUnit * 4
  }px), ${maxContentW}px)`,
};

export const breakpoints = {
  xs: 0,
  sm: 600,
  md: 900,
  lg: 1200,
  xl: 1536,
};
