import * as React from "react";
import { Global, css } from "@emotion/react";
import { useLocation } from "@reach/router";
import { structures } from "../styles/variables";
import globalStyle from "../styles/globalStyle";
import Header from "./Header";
import Footer from "./Footer";
import Head from "./Head";

const site = css``;

const contents = css`
  width: ${structures.responsiveContentWidth};
  margin: 0 auto;
`;

const Layout = ({ pageTitle, children }) => {
  const { pathname } = useLocation();
  return (
    <div css={site}>
      <Global styles={globalStyle} />
      <Head pageTitle={pageTitle} />
      <Header />
      <div className="site-contents" css={contents}>
        <main id="main">
          <header>
            {pathname === "/" ? <h2>{pageTitle}</h2> : <h1>{pageTitle}</h1>}
          </header>
          <div>{children}</div>
        </main>
      </div>
      <p>{pathname}</p>
      <Footer />
    </div>
  );
};

export default Layout;
