import { css } from "@emotion/react";
import { colors, typography, structures } from "./variables";
import { mq } from "./styling-tools";

const globalStyle = css`
  :root {
    --global-font-size: ${typography.globalFontSizeSP}px;
    --color-site-primary: ${colors.main};
    --color-text-main: ${colors.text};
    --color-text-title: ${colors.hedding};
    ${mq.up.md} {
      --global-font-size: ${typography.globalFontSize}px;
    }
  }
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  html {
    box-sizing: border-box;
    min-height: 100%;
    font-size: var(--global-font-size);
    color: var(--color-text-main);
    -webkit-text-size-adjust: 100%;
  }
  body {
    background-color: ${colors.body};
  }
  body,
  button,
  input,
  select,
  optgroup,
  textarea {
    font-family: ${typography.fontFamily};
    font-size: 1rem;
    font-weight: 400;
    line-height: ${typography.lineHeight};
    color: var(--color-text-main);
  }

  h1:not([class]),
  h2:not([class]),
  h4:not([class]),
  h5:not([class]),
  h6:not([class]) {
    color: var(--color-text-title);
  }

  dfn,
  cite,
  em,
  i {
    font-style: italic;
  }

  big {
    font-size: 125%;
  }
  img,
  picture {
    display: block;
    max-width: 100%;
    height: auto;
  }
  embed,
  iframe,
  object {
    max-width: 100%;
  }
  hr {
    height: 1px;
    margin: ${structures.gapV} 0;
    background-color: ${colors.border};
    border-style: none;
  }
  article {
    padding: 0;
    margin: 0;
  }
  button {
    padding: 0;
    cursor: pointer;
    background-color: transparent;
    border: none;
    outline: none;
    appearance: none;
  }

  a:not([class]) {
    color: ${colors.link.default};
    text-decoration: none;
    &:hover {
      color: ${colors.link};
      text-decoration: none;
      box-shadow: 0 1px 0 ${colors.link.hover};
    }
    &:focus,
    &:active,
    &:visited {
      color: ${colors.link.default};
      text-decoration: none;
    }
    > img {
      opacity: 1;
      transition: opacity 0.25s ease-out;
      &:hover {
        opacity: 0.7;
      }
    }
  }
`;

export default globalStyle;
