import * as React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import { css } from "@emotion/react";

import { mq } from "../styles/styling-tools";

const footer = css`
  width: 100%;
  background-color: #ccc;
  height: 6rem;
  ${mq.up.md} {
    background-color: #c11;
  }
`;
const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          siteUrl
          copyright
        }
      }
    }
  `);
  return (
    <footer id="colophon" className="site-footer" css={footer}>
      <Link to="/">{data.site.siteMetadata.title}</Link>
      <br />
      <small>{data.site.siteMetadata.copyright}</small>
    </footer>
  );
};

export default Footer;
