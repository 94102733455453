import * as React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import { useLocation } from "@reach/router";
import { css } from "@emotion/css";

import MainNav from "./main-navigation";

const header = css`
  background-color: rgba(#006633, 0.75);
`;
const siteTitle = css`
  font-size: 3rem;
  color: #666;
  font-weight: 700;
  margin: 0;
  padding: 0;
`;

const Header = () => {
  const { pathname } = useLocation();
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  return (
    <header id="masthead" className={"site-header " + header}>
      {pathname === "/" ? (
        <h1 className={siteTitle}>
          <Link to="/">{data.site.siteMetadata.title}</Link>
        </h1>
      ) : (
        <p className={siteTitle}>
          <Link to="/">{data.site.siteMetadata.title}</Link>
        </p>
      )}
      <MainNav />
    </header>
  );
};

export default Header;
